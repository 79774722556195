* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Home-Container-Main {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 50px;

  /* bottom: -7px; */
  background-color: #474b54;
  /* background-color: #a1b8f3; */
  object-fit: cover;
  overflow: hidden;
}

.where_we_excel {
  text-align: center;
}
.where-we-excel-container {
  padding-bottom: 2rem;
  margin: 1rem;
}

@media (max-width: 600px) {
  .where-we-excel-container {
    padding: 1rem;
  }
}
.next-steps1 {
  position: relative;
  padding: 5rem 2rem 2rem 2rem;
  margin: 0em 0;
}
.next-steps1 .l-container1 {
  position: relative;
  align-items: center;
  z-index: 3;
  width: 100%;
  font-size: medium;
}
.next-steps1::before {
  display: block;

  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  z-index: 1;

  max-width: 1153px;
  width: 100%;
  border-radius: 16px;
}
.next-steps__title1 {
  text-align: center;
  margin-bottom: 3rem;
  /* font-size: 2rem; */
  color: rgb(254, 200, 0, 0.9);
  padding: 1rem;
  margin: 2rem 2rem;
}
.next-steps__steps1 .next-steps__step1 {
  /* background: #fefefe; */
  -webkit-box-shadow: 0px 4px 10px rgba(19, 24, 200, 0.2);
  box-shadow: 0px 4px 10px rgba(19, 24, 200, 0.2);
  border-radius: 1rem;
  /* background: #e7e6e6; */
  margin: 1rem 1rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2rem;
  /* background: #c3ddf340; */
  background: linear-gradient(
    50deg,
    /* rgba(255, 255, 255, 0.4) 12%, */
    rgba(61, 69, 128, 0.4) 12%,
    rgba(255, 255, 255, 0.1) 77%
  );
  backdrop-filter: blur(17.5px);
  -webkit-backdrop-filter: blur(17.5px);
  border-radius: 10px;
}
.next-steps__steps1 .next-steps__step1:nth-of-type(1) {
  background-position: 100% center;
}
.next-steps__steps1 .next-steps__step1:nth-of-type(2) {
  background-position: 100% center;
}
.next-steps__steps1 .next-steps__step1:nth-of-type(4) {
  background-position: 100% center;
}
.next-steps__steps1 .next-steps__step1 .next-steps__step-title1 {
  font-weight: 500;
  margin-bottom: 0.7rem;
  color: #ffdf61;
}
.next-steps__steps1 .next-steps__step1 .next-steps__step-desc1 {
  font-size: 0.938rem;
  color: #ffffff;
}

@media (min-width: 769px) {
  .next-steps1 {
    padding: 5rem 0 7.5rem 0;
  }
  .next-steps__steps1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-around;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .next-steps__steps1 .next-steps__step1 {
    padding: 2rem 2rem 4rem 2rem;
    width: 47%;
    margin: 1rem 0;
  }
}
@media (min-width: 1025px) {
  .next-steps__steps1 .next-steps__step1 {
    width: 30%;
    /* height: 300px; */
  }
  .next-steps--3-steps1 .next-steps__step1 {
    width: 30%;
  }
}

.image-decision1 {
  height: 8em;
  opacity: 0.2;
  margin-right: 3rem;
}
.image-decision2 {
  height: 13em;
  opacity: 0.3;
}
.image-decision3 {
  height: 19rem;
  opacity: 0.5;
}

@media (max-width: 769px) {
  .image-decision1 {
    display: none;
  }
  .image-decision2 {
    display: none;
  }
  .image-decision3 {
    display: none;
  }
}

@media ((min-width: 769px) and (max-width:1050px )) {
  .image-decision1 {
    display: none;
  }
  .image-decision2 {
    display: none;
  }
  .image-decision3 {
    height: 18rem;
  }
}
